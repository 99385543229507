import React, { useEffect, useMemo } from "react";

import dayjs from "dayjs";

import { OrderBy, useDriveContext } from "@drive/context";

import { NoBookings } from "../../_lib/NoBookings";

import { Spinner } from "~/components/Spinner";
import Card from "@drive/components/Card";
import { Typography } from "~/components/Typography";

import Box from "@material-ui/core/Box";
import Grid from "@material-ui/core/Grid";
import { makeStyles } from "@material-ui/core";

import { Appointment } from "@api/graphql/types";

const useStyles = makeStyles(() => {
	return {
		content: {
			padding: 24,
		},
		noBookings: {
			padding: 24,
		},
		spinner: {
			width: 48,
			height: 48,
			alignSelf: "center",
		},
		month: {
			margin: "24px 0",
		},
		monthTitle: {
			fontSize: 30,
		},
	};
});

export const Display: React.FC = () => {
	const { appointments, loading, error, filter, setFilter } =
		useDriveContext();
	const styles = useStyles();

	useEffect(() => {
		if (filter.orderBy !== OrderBy.PAST) {
			setFilter({ orderBy: OrderBy.PAST });
		}
	}, [filter.orderBy, setFilter]);

	const content = useMemo(() => {
		if (loading) {
			return <Spinner className={styles.spinner} />;
		}

		if (!appointments.length) {
			return (
				<div className={styles.noBookings}>
					<NoBookings />
				</div>
			);
		}

		function groupAppointmentsByMonth(
			output: any,
			appointment: Appointment,
		) {
			const { appointmentAt } = appointment;
			const month = dayjs(appointmentAt).format("MMMM");

			(output[month] ??= [])?.push(appointment);
			return output;
		}

		const appointmentsByMonth =
			Object.entries(
				appointments?.reduce(groupAppointmentsByMonth, {}),
			) || [];

		const currentMonth = dayjs(new Date().toISOString()).format("MMMM");

		return (
			<Box className={styles.content}>
				<Grid container spacing={2}>
					{appointmentsByMonth?.map(
						(appointmentByMonth: any[], index: number) => {
							const monthTitle =
								currentMonth === appointmentByMonth?.[0]
									? "This Month"
									: appointmentByMonth?.[0];
							return (
								<Grid
									container
									spacing={2}
									className={styles.month}
									key={`appointment-group-${index}`}
								>
									<Grid
										item
										xs={12}
										sm={12}
										md={12}
										lg={12}
										xl={12}
									>
										<Typography
											className={styles.monthTitle}
											variant="caption"
										>
											{monthTitle}
										</Typography>
									</Grid>
									{appointmentByMonth?.[1]?.map(
										(
											appointment: Appointment,
											index: number,
										) => {
											return (
												<Card
													key={`appointment-${index}`}
													appointment={appointment}
												/>
											);
										},
									)}
								</Grid>
							);
						},
					)}
				</Grid>
			</Box>
		);
	}, [appointments, loading, styles]);

	return (
		<Box
			display="flex"
			justifyContent={loading || error ? "center" : ""}
			flexDirection="column"
			flexWrap="nowrap"
			height="100%"
		>
			{content}
		</Box>
	);
};
